import services from "../axios/dbManag";
import accentMap from "@/functions/sostituzioneLettereAccentate";

export const addCorso = (corso) => {
  return services.dbManagV2
    .post("/snm/corsi/add", {
      ...corso,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editCorso = (corso) => {
  return services.dbManagV2
    .post("/snm/corsi/edit", {
      ...corso,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deleteCorso = (id) => {
  return services.dbManag
    .post("/snm/corsi/del", {
      id: id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const detailCorso = (id) => {
  return services.dbManag
    .post("/snm/corsi/view", {
      id,
    })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return false;
    });
};

export const pubblicaCorso = (id) => {
  return services.dbManag
    .post("/snm/corsi/publish", {
      id: id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addPartecipanteCorso = (partecipante) => {
  return services.dbManag
    .post("/snm/corsi/partecipanti/add", {
      ...partecipante,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deletePartecipanteCorso = (
  id_iscrizione,
  id_corso,
  id_persona
) => {
  return services.dbManag
    .post("/snm/corsi/partecipanti/del", {
      id_iscrizione,
      id_corso,
      id_persona,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const setIdoneoPartecipantiCorso = (id_corso, id_arr, azione) => {
  return services.dbManag
    .post("/snm/corsi/partecipanti/eligibility", {
      id_corso: id_corso,
      id_arr: id_arr,
      azione: azione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const setPresentePartecipantiCorso = (id_corso, id_arr, azione) => {
  return services.dbManag
    .post("/snm/corsi/partecipanti/presente", {
      id_corso: id_corso,
      id_arr: id_arr,
      azione: azione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const setIscrizionePartecipanteCorso = (
  id_iscrizione,
  id_corso,
  id_persona,
  azione
) => {
  return services.dbManag
    .post("/snm/corsi/partecipanti/subscription/approve", {
      id_iscrizione,
      id_corso,
      id_persona,
      azione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const setScontoPartecipanteCorso = (id, sconta) => {
  return services.dbManag
    .post("/snm/corsi/partecipanti/discount", {
      id: id,
      sconta: sconta,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const setPagamentoPartecipanteCorso = (pagamento) => {
  return services.dbManag
    .post("/snm/corsi/partecipanti/payment", {
      ...pagamento,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const getDocumentiCorso = (id_Corso) => {
  return services.dbManag
    .post("/snm/corsi/documenti/list", {
      id_Corso,
      rowstoskip: 0,
      fetchrows: 10,
      sortcolumn: "id",
      sortorder: "asc",
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const downloadDocumenti = (
  id_persona,
  id_corso,
  nominativo_completo
) => {
  return services.downloadFile
    .get("/download/corsi/persona/zip", {
      params: { id_persona, id_corso },
    })
    .then((res) => {
      const indexVirgola = nominativo_completo.indexOf(",");

      let cognome = nominativo_completo.substring(0, indexVirgola);
      let nome = nominativo_completo.substring(
        indexVirgola + 2,
        nominativo_completo.length - 11
      );
      nome = nome
        .replace(/[\s']/g, "_")
        .replace(
          /[ÀÁÂÃÄÅàáâãäåÈÉÊËèéêëÌÍÎÏìíîïÒÓÔÕÖÙÚÛÜùúûüÝýÿÑñÇçÆæØøß]/g,
          function (matched) {
            return accentMap[matched];
          }
        );
      cognome = cognome
        .replace(/[\s']/g, "_")
        .replace(
          /[ÀÁÂÃÄÅàáâãäåÈÉÊËèéêëÌÍÎÏìíîïÒÓÔÕÖÙÚÛÜùúûüÝýÿÑñÇçÆæØøß]/g,
          function (matched) {
            return accentMap[matched];
          }
        );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${cognome}_${nome}_${id_corso}.zip`);
      document.body.appendChild(link);
      link.click();
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
