import services from "@/axios/dbManag";

export const deleteDocumento = (item_Id, id_Corso) => {
  return services.dbManag
    .post("/snm/corsi/documenti/del", {
      id_Corso,
      item_Id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};

export const delFile = (item_Id) => {
  return services.dbManag
    .post("/snm/corsi/partecipanti/documenti/del", {
      item_Id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};
