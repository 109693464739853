<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_edit_corso"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="!onlyView" class="modal-title">Modifica corso</h5>
            <h5 v-if="onlyView" class="modal-title">Dettaglio corso</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="mx-auto my-auto text-center py-5 my-5" v-if="!caricato">
              <div class="spinner-border text-gray-600" role="status"></div>
              <span class="text-gray-600 ps-3 fs-2">Loading</span>
            </div>
            <div class="card-body p-6" v-if="caricato">
              <form autocomplete="off">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5">
                  <div class="row pb-4">
                    <div class="col-md-12">
                      <label class="fw-bold text-gray-600">Denominazione</label>
                      <Field
                        name="denominazione"
                        type="text"
                        v-model="denominazione"
                        class="form-control"
                        :disabled="onlyView"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="denominazione"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-600"
                        >Tipologia corso</label
                      >
                      <Field
                        name="id_Tipo_Corso"
                        as="select"
                        class="form-select"
                        v-model="id_Tipo_Corso"
                        @change="
                          setCorsoTipo($event.target.value);
                          getSpecializzCorsi($event.target.value);
                          setCorsoSottotipo(null);
                          resetCorsiSpec();
                          setCorsoCostoBase($event);
                        "
                        :disabled="onlyView"
                      >
                        <option value="0">Seleziona</option>
                        <option
                          v-for="tipo in snm_corsi_tipo"
                          :key="tipo.id"
                          :value="tipo.id"
                          :data-costo="tipo.costo_base"
                        >
                          {{ tipo.label }}
                        </option>
                      </Field>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_Tipo_Corso"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-600"
                        >Specializzazione corso</label
                      >
                      <SelectInput
                        v-if="id_Tipo_Corso != 9"
                        :options="snm_corsi_sottotipo"
                        name="id_Sottotipo_Corso"
                        id="id_Sottotipo_Corso"
                        placeholder="Seleziona"
                        :value="id_Sottotipo_Corso"
                        :disabled="!id_Tipo_Corso || onlyView"
                        @changeSelect="setCorsoSottotipo($event)"
                      />
                      <Multiselect
                        v-if="id_Tipo_Corso == 9"
                        v-model="selectedSottotipo"
                        :options="snm_corsi_sottotipo"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        @input="inputElement"
                        :disabled="!id_Tipo_Corso || onlyView"
                      />
                      <Field
                        v-if="id_Tipo_Corso == 9"
                        name="id_sottotipo_corso_agg"
                        type="hidden"
                        v-model="id_sottotipo_corso_agg"
                        class="form-control"
                      />
                      <ErrorMessage
                        v-if="id_Tipo_Corso == 9"
                        class="help-message"
                        style="color: red"
                        name="id_sottotipo_corso_agg"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-600">Costo €</label>
                      <Field
                        name="costo"
                        type="text"
                        v-model="costo"
                        class="form-control"
                        :disabled="onlyView"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="costo"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Inizio corso*</label>
                      <!-- :minDate="new Date(data_Inizio)" -->
                      <Datepicker
                        v-model="data_Inizio"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        :disabled="onlyView"
                      />
                      <Field
                        name="data_Inizio"
                        type="hidden"
                        class="form-control"
                        v-model="data_Inizio"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_Inizio"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Fine corso*</label>
                      <Datepicker
                        v-model="data_Fine"
                        placeholder=""
                        :format="format"
                        :minDate="new Date(data_Inizio)"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        :disabled="onlyView"
                      />
                      <Field
                        name="data_Fine"
                        type="hidden"
                        class="form-control"
                        v-model="data_Fine"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_Fine"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Inizio prenotazioni*</label
                      >
                      <Datepicker
                        v-model="iscr_Inizio"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        :disabled="onlyView"
                      />
                      <Field
                        name="iscr_Inizio"
                        type="hidden"
                        class="form-control"
                        v-model="iscr_Inizio"
                      />
                      <ErrorMessage
                        v-show="!onlyView"
                        class="help-message"
                        style="color: red"
                        name="iscr_Inizio"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Fine prenotazioni*</label
                      >
                      <Datepicker
                        v-model="iscr_Fine"
                        :minDate="new Date(iscr_Inizio)"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        :disabled="onlyView"
                      />
                      <Field
                        name="iscr_Fine"
                        type="hidden"
                        class="form-control"
                        v-model="iscr_Fine"
                      />
                      <ErrorMessage
                        v-show="!onlyView"
                        class="help-message"
                        style="color: red"
                        name="iscr_Fine"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Data pubblicazione</label
                      >
                      <Datepicker
                        v-model="pubblicazione_Sul_Sito"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        :disabled="onlyView"
                      />
                      <Field
                        name="pubblicazione_Sul_Sito"
                        type="hidden"
                        class="form-control"
                        v-model="pubblicazione_Sul_Sito"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="pubblicazione_Sul_Sito"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Visibile online*</label
                      >
                      <SelectInput
                        :options="lookupSTDSiNo"
                        name="visibile_online"
                        placeholder="-"
                        :value="visibile_online"
                        @changeSelect="visibile_online = $event"
                        :disabled="onlyView"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Iscrizione online*</label
                      >
                      <SelectInput
                        :options="lookupSTDSiNo"
                        name="iscrizione_online"
                        placeholder="-"
                        :value="iscrizione_online"
                        @changeSelect="values.id_livello_visibilita = null"
                        :disabled="onlyView"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Livello di visibilità*</label
                      >
                      <SelectInput
                        :options="snm_corsi_livelli_visibilita"
                        :disabled="
                          !onlyView && iscrizione_online == 1 ? false : true
                        "
                        name="id_livello_visibilita"
                        placeholder="-"
                        :value="id_livello_visibilita"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Crediti n.</label>
                      <Field
                        name="crediti"
                        type="text"
                        v-model="crediti"
                        class="form-control"
                        :disabled="onlyView"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="crediti"
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="fw-bold text-gray-600">Curatore</label>
                      <Field
                        name="curatore"
                        type="text"
                        v-model="curatore"
                        class="form-control"
                        :disabled="onlyView"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="" v-if="!onlyView">
                      <DropZone
                        @files-dropped="addFile($event, supportedFormatCorsi)"
                        #default="{ dropZoneActive }"
                      >
                        <label for="file-input-edit" style="cursor: pointer">
                          <span v-if="dropZoneActive">
                            <span>Lascia qui il file </span>
                            <span class="smaller">per aggiungerlo</span>
                          </span>
                          <span v-else>
                            <span>Trascina qui il tuo file</span>
                            <span class="smaller">
                              oppure
                              <strong><em>clicca qui</em></strong>
                              per selezionare il file
                            </span>
                          </span>
                        </label>
                        <input
                          type="file"
                          id="file-input-edit"
                          style="display: none"
                          :accept="supportedFormatCorsi"
                          @change="onInputChange"
                        />
                        <ul class="image-list" v-if="file.id">
                          <FilePreview
                            :key="file.id"
                            :file="file"
                            tag="li"
                            @remove="removeFile"
                          />
                        </ul>
                      </DropZone>
                      <div class="form-text">
                        Tipi di file concessi: {{ supportedFormatCorsi }}
                      </div>
                    </div>
                  </div>
                  <ul
                    class="image-list"
                    style="margin-top: 2.5rem"
                    v-if="documento"
                  >
                    <PreviewUploadedFile
                      :key="documento.guid_documento"
                      :name="documento.file_name"
                      tag="li"
                      @downloadFile="
                        downloadDocumento(
                          documento.guid_documento,
                          documento.file_name
                        )
                      "
                      @removeFile="
                        removeDoc(
                          documento.guid_documento,
                          new Date(documento.ts).getFullYear(),
                          documento.id_tipo,
                          0
                        )
                      "
                    />
                  </ul>
                  <!--<div class="row">
                  <div class="col-lg-3">
                    <label class="fw-bold text-gray-600">
                      Informazioni corso:
                    </label>
                  </div>
                  <div class="col-lg-9 d-flex ps-0 pe-xl-11">
                    <input
                      type="text"
                      class="form-control me-2"
                      placeholder=""
                      aria-label=""
                    />
                    <div
                      class="image-input image-input-outline"
                      data-kt-image-input="true"
                      style="background-image: url(media/avatars/blank.png)"
                    >
                      <label
                        class="btn btn-icon btn-active-color-primary bg-white m-0 p-0"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="Change file"
                      >
                        <i class="bi bi-upload fs-7"></i>


                        <input
                          type="file"
                          name="avatar"
                          accept=".pdf, .doc, .jpeg, .ppt, .xls, .docx, .pptx, .xlsx, .jpg, .jpeg, .png"
                        />
                        <input type="hidden" name="avatar_remove" />

                      </label>
                    </div>
                  </div>
                </div>-->
                  <hr class="mb-4 mt-4" />
                  <div class="row pt-3">
                    <div class="col-md-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="estero"
                        id="italia"
                        :checked="estero == 0"
                        @click="setEstero(0)"
                        :disabled="onlyView"
                      />
                      <label class="fw-bold text-gray-600 ps-2" for="italia"
                        >Italia</label
                      >
                    </div>
                    <div class="col-md-2 mb-4">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="estero"
                        id="estero"
                        :checked="estero == 1"
                        @click="setEstero(1)"
                        :disabled="onlyView"
                      />
                      <label class="fw-bold text-gray-600 ps-2" for="estero"
                        >Estero</label
                      >
                      <Field
                        v-model="estero"
                        type="hidden"
                        name="estero"
                        id="italia"
                      />
                    </div>
                  </div>
                  <div class="row pb-4" v-if="estero">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Nazione*</label>
                      <SelectInput
                        :options="statiResidenza"
                        :value="stato"
                        name="stato"
                        placeholder="Seleziona"
                        @changeSelect="setNazioneCorso($event)"
                        :disabled="onlyView"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Località*</label>
                      <Field
                        name="localita"
                        type="text"
                        v-model="localita"
                        class="form-control"
                        :disabled="onlyView || !stato"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="localita"
                      />
                    </div>
                  </div>
                  <div class="row pb-4" v-else>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Regione</label>
                      <SelectInput
                        :options="istat_regioni_province"
                        name="id_Regione"
                        :value="id_Regione"
                        @changeSelect="setRegioneCorso($event)"
                        :disabled="onlyView"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Provincia</label>
                      <SelectInput
                        :options="province"
                        name="id_Provincia"
                        :value="id_Provincia"
                        @changeSelect="setProvinciaCorso($event)"
                        :disabled="onlyView || !id_Regione"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Comune</label>
                      <Field
                        name="comune"
                        type="text"
                        class="form-control"
                        @input="
                          spliceComuniIstat();
                          setComuni(comune_txt, id_Provincia);
                        "
                        @focus="inputFocus = 'comuneRD'"
                        @keyup.delete="spliceComuniIstat()"
                        v-model="comune_txt"
                        :disabled="!id_Provincia || onlyView"
                        autocomplete="off"
                      />
                      <Field
                        name="id_Comune"
                        type="hidden"
                        v-model="id_Comune"
                        class="form-control"
                      />
                      <ListComuni
                        v-if="comuniIstat && inputFocus === 'comuneRD'"
                        :list="comuniIstat"
                        @hideList="spliceComuniIstat"
                        @selectedElement="
                          spliceComuniIstat();
                          id_Comune = $event.value;
                          comune_txt = $event.name;
                        "
                      ></ListComuni>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_Comune"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-6">
                      <label class="fw-bold text-gray-600">Luogo</label>
                      <Field
                        name="luogo"
                        type="text"
                        v-model="luogo"
                        class="form-control"
                        :disabled="onlyView"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="luogo"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Telefono</label>
                      <Field
                        name="telefono"
                        type="text"
                        v-model="telefono"
                        class="form-control"
                        :disabled="onlyView"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="telefono"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Fax</label>
                      <Field
                        name="fax"
                        type="text"
                        v-model="fax"
                        class="form-control"
                        :disabled="onlyView"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="fax"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">C.A.P.</label>
                      <Field
                        name="cap"
                        type="text"
                        v-model="cap"
                        class="form-control"
                        :disabled="onlyView"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="cap"
                      />
                    </div>
                  </div>
                  <hr class="mb-4 mt-4" />
                  <div class="row pb-4">
                    <p>Organizzatore:</p>
                    <div class="col-md-5">
                      <label class="fw-bold text-gray-600">Tipologia</label>
                      <SelectInput
                        :options="snm_tipo_organizzazione"
                        name="org_Tipo"
                        :value="org_Tipo"
                        @changeSelect="setCorsoTipoOrganizzazione($event)"
                        :disabled="onlyView"
                      />
                    </div>
                    <div class="col-md-7">
                      <label class="fw-bold text-gray-600">Denominazione</label>
                      <div v-if="org_Tipo === 1">
                        <Field
                          name="org_Denominazione_1"
                          type="text"
                          v-model="org_Denominazione_1"
                          class="form-control"
                          @change="org_Denominazione = $event.target.value"
                          :disabled="onlyView"
                        />
                      </div>
                      <div v-else-if="org_Tipo === 2">
                        <Field
                          name="selectedComitati"
                          as="select"
                          class="form-select"
                          v-model="selectedComitati"
                          @change="
                            org_Denominazione = $event.target.value;
                            selectedComitati = $event.target.value;
                          "
                          :disabled="onlyView"
                        >
                          <option :value="null">Seleziona</option>
                          <option
                            v-for="comitato in comitati"
                            :key="comitato.label"
                            :value="comitato.label"
                          >
                            {{ comitato.label }}
                          </option>
                        </Field>
                      </div>
                      <div v-else-if="org_Tipo === 3">
                        <input
                          name="nome_societa"
                          type="text"
                          class="form-control"
                          @input="
                            getSocieta($event.target.value);
                            org_Denominazione = '';
                            nome_societa === ''
                              ? (org_Denominazione = '')
                              : (org_Denominazione = '');
                          "
                          v-model="nome_societa"
                          autocomplete="off"
                          :disabled="onlyView"
                        />
                        <ListSocieta
                          :focusOnInput="true"
                          :list="societa"
                          @hideList="resetSocieta"
                          @selectedSocieta="
                            resetSocieta();
                            nome_societa = $event.name;
                            org_Denominazione = $event.name;
                          "
                        ></ListSocieta>
                      </div>
                      <div v-else-if="org_Tipo === 6">
                        <!--<Field
                        name="org_Denominazione_6"
                        type="text"
                        v-model="org_Denominazione_6"
                        class="form-control"
                      />-->
                        <TextInput
                          name="org_Denominazione_6"
                          v-model="org_Denominazione_6"
                          type="text"
                          @InputEvent="setOrganizzatoreAltro($event)"
                          :disabled="onlyView"
                        />
                      </div>
                      <div v-else>
                        <input type="text" disabled="" class="form-control" />
                      </div>
                      <Field
                        name="org_Denominazione"
                        type="hidden"
                        v-model="org_Denominazione"
                        class="form-control"
                        id="org_Denominazione_edit"
                        :disabled="onlyView"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="org_Denominazione"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalEdit"
              @click="$emit('resetIdCorso')"
            >
              Chiudi
            </button>
            <button
              v-if="!onlyView"
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, computed, ref, watch } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import TextInput from "@/components/components-fit/utility/inputs/TextInput.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import ListComuni from "@/components/components-fit/utility/ListComuni.vue";
import ListSocieta from "@/components/components-fit/utility/ListSocieta.vue";
import "vue3-date-time-picker/dist/main.css";
import { detailCorso, editCorso, getDocumentiCorso } from "@/requests/snmCorsi";
import Multiselect from "@vueform/multiselect";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import DropZone from "../../Drag-and-Drop-File/DropZone.vue";
import FilePreview from "../../Drag-and-Drop-File/FilePreview.vue";
import useFileList from "@/composables/file-list";
import PreviewUploadedFile from "../../Drag-and-Drop-File/PreviewUploadedFile.vue";
import { sendFiles } from "@/requests/fileSender";
import { deleteDocumento } from "@/requests/documentiSnmCorsi";

import { alertFailed, alertSuccess } from "@/composables/swAlert";
import { downloadElement } from "@/requests/downloadFiles";
import { forEachLeadingCommentRange } from "typescript";

export default defineComponent({
  name: "EditCorso",
  components: {
    Loading,
    Datepicker,
    Form,
    SelectInput,
    TextInput,
    ErrorMessage,
    Field,
    ListComuni,
    ListSocieta,
    DropZone,
    FilePreview,
    PreviewUploadedFile,
    Multiselect,
  },
  props: {
    id_corso: {
      type: Number,
      required: true,
    },
    onlyView: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["refreshList", "resetIdCorso"],
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const snm_corsi_tipo = computed(() =>
      store.getters.getStateFromName("snm_corsi_tipo")
    );
    const snm_corsi_sottotipo = computed(() =>
      store.getters.getStateFromName("snm_corsi_sottotipo")
    );
    const istat_regioni_province = computed(() =>
      store.getters.getStateFromName("istat_regioni_province")
    );
    const statiResidenza = computed(() =>
      store.getters.getStateFromName("stati_residenza")
    );
    const snm_tipo_organizzazione = computed(() =>
      store.getters.getStateFromName("snm_tipo_organizzazione")
    );
    const snm_corsi_livelli_visibilita = computed(() =>
      store.getters.getStateFromName("snm_corsi_livelli_visibilita")
    );
    //const comitati = computed(() => store.getters.getStateFromName("comitati"));

    const keys = ref("sct|irp|sr|scto|c|sclv");
    if (
      !snm_corsi_tipo.value ||
      !istat_regioni_province.value ||
      !statiResidenza.value ||
      !snm_tipo_organizzazione.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const getSpecializzCorsi = (id) => {
      if (!isNaN(id) && String(id) != "") {
        store.dispatch("setStoreData", {
          keys: { id_Tipo_Corso: id },
          apiLink: globalApi.LOOKUP_SPECIALIZZAZIONE_CORSI,
        });
      }
    };

    const alertCampiObb = ref(false);
    const defaultStagione = computed(() => store.getters.defaultStagione);
    const stagione = ref(null);
    stagione.value = defaultStagione.value;
    const denominazione = ref("");
    const id_Tipo_Corso = ref(0);
    const id_Sottotipo_Corso = ref(null);
    const costo = ref(null);
    const data_Inizio = ref(null);
    const data_Fine = ref(null);
    const iscr_Inizio = ref(null);
    const iscr_Fine = ref(null);
    const pubblicazione_Sul_Sito = ref(null);
    const crediti = ref(null);
    const curatore = ref("");
    const estero = ref(0);
    const id_Regione = ref(0);
    const id_Provincia = ref(79);
    const comune_txt = ref("");
    const inputFocus = ref("");
    const id_Comune = ref(0);
    const selectedProvincia = ref(false);
    const stato = ref("");
    const localita = ref("");
    const luogo = ref("");
    const telefono = ref("");
    const fax = ref("");
    const cap = ref("");
    const org_Tipo = ref(0);
    const org_Denominazione = ref("");
    const org_Denominazione_1 = ref("");
    const org_Denominazione_3 = ref("");
    const org_Denominazione_6 = ref("");
    const nome_societa = ref("");
    const selectedComitati = ref(null);
    const documento = ref({});
    const visibile_online = ref(null);
    const iscrizione_online = ref(null);
    const id_livello_visibilita = ref(null);

    const setCorsoStagione = (id) => {
      stagione.value = parseInt(id);
    };

    const setCorsoTipo = (id) => {
      id_Tipo_Corso.value = parseInt(id);
    };

    const setCorsoCostoBase = (e) => {
      costo.value = e.target.selectedOptions[0].getAttribute("data-costo");
    };

    const setCorsoSottotipo = (id) => {
      id_Sottotipo_Corso.value = parseInt(id);
    };

    const resetCorsiSpec = () => {
      store.commit("spliceArrayFromName", "snm_corsi_sottotipo");
      //specializzCorsi.value = null;
    };

    const province = ref([]);

    const setRegioneCorso = (event) => {
      id_Regione.value = event;
      id_Provincia.value = null;
      comune_txt.value = "";
      id_Comune.value = 0;
      province.value.splice(0, province.value.length);
      istat_regioni_province.value.forEach((element) => {
        if (element.codR === parseInt(event)) {
          element.Province.forEach((prov) => {
            province.value.push(prov);
          });
        }
      });
    };

    const setProvinciaCorso = (id) => {
      id_Provincia.value = id;
      comune_txt.value = null;
      id_Comune.value = 0;
    };

    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    const setComuni = (comuneString, id) => {
      if (comuneString.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: comuneString,
            codp: id,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };

    const setNazioneCorso = (id) => {
      stato.value = id;
      localita.value = null;
    };

    const setCorsoTipoOrganizzazione = (id) => {
      org_Denominazione.value = "";
      nome_societa.value = "";
      org_Tipo.value = parseInt(id);
      if (org_Tipo.value === 1) {
        org_Denominazione_1.value = "Federazione Italiana Tennis e Padel";
        org_Denominazione.value = org_Denominazione_1.value;
      }
    };

    const getSocieta = (string) => {
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };
    const spliceSocieta = () => {
      nome_societa.value = "";
      org_Denominazione.value = "";
      resetSocieta();
    };

    const setCorsoOrganizzatoreComitato = (id) => {
      selectedComitati.value = parseInt(id);
    };

    const schema = yup.object().shape({
      denominazione: yup.string().required("Obbligatorio"),
      id_Tipo_Corso: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .moreThan(0, "Obbligatorio")
        .nullable(),
      id_Sottotipo_Corso: yup
        .number()
        .when("id_Tipo_Corso", {
          is: (id_Tipo_Corso) => id_Tipo_Corso != 9,
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .test("notEmpty", "Obbligatorio", function (value) {
              if (
                document.getElementById("id_Sottotipo_Corso") &&
                document.getElementById("id_Sottotipo_Corso").length < 2
              ) {
                console.log(
                  document.getElementById("id_Sottotipo_Corso").length
                );
                return true;
              } else {
                console.log(
                  document.getElementById("id_Sottotipo_Corso")
                    ? document.getElementById("id_Sottotipo_Corso")
                    : ""
                );
                if (value != null) {
                  return true;
                } else {
                  return false;
                }
              }
            })
            .nullable(),
        })
        .nullable(),
      id_sottotipo_corso_agg: yup.string().when("id_Tipo_Corso", {
        is: (id_Tipo_Corso) => id_Tipo_Corso == 9,
        then: yup.string().required("Obbligatorio").nullable(),
      }),
      costo: yup
        .number()
        .required("Obbligatorio")
        .nullable()
        .transform((value) => (isNaN(value) ? undefined : value)),
      data_Inizio: yup
        .string()
        .required("Obbligatorio")
        .nullable()
        .typeError("Obbligatorio"),
      data_Fine: yup
        .string()
        .required("Obbligatorio")
        .nullable()
        .typeError("Obbligatorio"),
      visibile_online: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      iscrizione_online: yup
        .number()
        .nullable()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      id_livello_visibilita: yup
        .number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when("iscrizione_online", {
          is: (value) => {
            return value == 1;
          },
          then: yup
            .number()
            .required("Obbligatorio")
            .transform((value) => (isNaN(value) ? undefined : value)),
          otherwise: yup.number().nullable(),
        }),
      crediti: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio"),
      curatore: yup.string(),
      estero: yup.number().required("Obbligatorio").oneOf([0, 1]),
      stato: yup.string().when("estero", {
        is: (estero) => estero === 1,
        then: yup.string().required("Obbligatorio").nullable(),
      }),
      localita: yup.string().when("estero", {
        is: (estero) => estero === 1,
        then: yup.string().required("Obbligatorio").nullable(),
      }),
      id_Regione: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .when("estero", {
          is: (estero) => estero === 0,
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required("Obbligatorio")
            .moreThan(0, "Obbligatorio")
            .nullable(),
        }),
      id_Provincia: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .when("estero", {
          is: (estero) => estero === 0,
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required("Obbligatorio")
            .moreThan(0, "Obbligatorio")
            .nullable(),
        }),
      id_Comune: yup.number().when("estero", {
        is: (estero) => estero === 0,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? 0 : value))
          .required("Obbligatorio")
          .nullable()
          .moreThan(0, "Obbligatorio"),
      }),
      org_Tipo: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      org_Denominazione: yup.string().when("org_Tipo", {
        is: (org_Tipo) => org_Tipo > 0,
        then: yup.string().required("Obbligatorio"),
      }),
      iscr_Inizio: yup.string().required("Obbligatorio").nullable(),
      iscr_Fine: yup.string().required("Obbligatorio").nullable(),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      disableButton.value = true;
      isLoading.value = true;
      values.id = props.id_corso;
      alertCampiObb.value = false;

      values.stagione = defaultStagione.value;

      editCorso(values).then(async (value) => {
        if (value.status == 200) {
          let bodyFormData = new FormData();
          if (file.value.id) {
            if (documento.value) {
              bodyFormData.append("item_id", documento.value.guid_documento);
              bodyFormData.append(
                "anno",
                new Date(documento.value.ts).getFullYear()
              );
              bodyFormData.append("id_Tipo", documento.value.id_tipo);
              bodyFormData.append("acl", 0);
              bodyFormData.append("id_Corso", props.id_corso);
              bodyFormData.append("file", file.value.file);
              await sendFiles(
                bodyFormData,
                globalApi.EDIT_DOCUMENTO_CORSI
              ).then((res) => {
                const { status, data } = res;
                if (status != 200) {
                  file.value.status = false;
                  alertFailed(
                    data.message
                      ? data.message
                      : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                  );
                } else {
                  file.value.status = true;
                  const confirmButtonText = `
              <span
                type="button"
                class="me-3 badge bg-light-danger rounded text-danger fs-5"
                data-bs-dismiss="modal"
                data-bs-target="#modal_edit_corso"
              >
                Chiudi
              </span>`;
                  Swal.fire({
                    html: "Documento modificato con successo",
                    icon: "success",
                    buttonsStyling: false,
                    customClass: {
                      confirmButton:
                        "me-3 badge bg-light-danger rounded text-danger fs-5",
                    },
                    confirmButtonText: confirmButtonText,
                    allowOutsideClick: false,
                  }).then(() => {
                    disableButton.value = false;
                    isLoading.value = false;
                    bodyFormData = new FormData();
                    resetField();
                    document.getElementById("closeModalEdit").click();
                    emit("refreshList");
                    documento.value = {};
                    file.value = {};
                  });
                }
              });
            } else {
              bodyFormData.append(
                "anno",
                new Date(data_Inizio.value).getFullYear()
              );
              bodyFormData.append("id_Tipo", id_Tipo_Corso.value);
              bodyFormData.append("acl", 0); // acl
              bodyFormData.append("id_Corso", props.id_corso);
              bodyFormData.append("file", file.value.file);
              await sendFiles(
                bodyFormData,
                globalApi.AGGIUNGI_DOCUMENTO_CORSI
              ).then((res) => {
                if (res.data.message) {
                  file.value.status = false;
                  alertFailed(
                    res.data.message
                      ? res.data.message
                      : "Attenzione! Si è verificato un errore nell'invio del file. Riprovare più tardi"
                  );
                  return;
                } else {
                  file.value.status = true;
                }
              });
              Swal.fire({
                html: "Corso modificato con successo",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              }).then(() => {
                resetField();
                document.getElementById("closeModalEdit").click();
                emit("refreshList");
                isLoading.value = false;
                disableButton.value = false;
                documento.value = {};
                jsonDetailCorso.value = {};
                bodyFormData = new FormData();
                file.value.id = 0;
              });
            }
          } else {
            alertSuccess("Corso Modificato con successo", "modal_edit_corso");
            isLoading.value = false;
            disableButton.value = false;
            documento.value = {};
            jsonDetailCorso.value = {};
            bodyFormData = new FormData();
            file.value.id = 0;
            emit("refreshList");
          }
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            isLoading.value = false;
            disableButton.value = false;
          });
        }
      });
    };

    const onInvalidSubmit = (v) => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      stagione.value = defaultStagione.value;
      denominazione.value = "";
      id_Tipo_Corso.value = 0;
      id_Sottotipo_Corso.value = null;
      id_sottotipo_corso_agg.value = "";
      costo.value = null;
      data_Inizio.value = null;
      data_Fine.value = null;
      iscr_Inizio.value = null;
      iscr_Fine.value = null;
      pubblicazione_Sul_Sito.value = null;
      crediti.value = null;
      curatore.value = "";
      estero.value = 0;
      id_Regione.value = 0;
      id_Provincia.value = 0;
      comune_txt.value = "";
      inputFocus.value = "";
      id_Comune.value = 0;
      selectedProvincia.value = false;
      stato.value = "";
      localita.value = "";
      luogo.value = "";
      telefono.value = "";
      fax.value = "";
      cap.value = "";
      org_Tipo.value = 0;
      org_Denominazione.value = "";
      nome_societa.value = "";
      org_Denominazione_1.value = "";
      org_Denominazione_3.value = "";
      org_Denominazione_6.value = "";
      documento.value = {};
      file.value = {};
      selectedSottotipo.value = [];
      visibile_online.value = null;
      iscrizione_online.value = null;
      id_livello_visibilita.value = null;
    };

    const validateNumber = () => {
      //validazione numero
    };

    const setOrganizzatoreAltro = (event) => {
      org_Denominazione.value = event;
    };

    const jsonDetailCorso = ref({});
    const id_sottotipo_corso_agg = ref([]);
    const sottotipo_agg = ref("");
    const selectedSottotipo = ref([]);

    const loadCorso = async (id) => {
      // jsonDetailCorso.value = {};
      caricato.value = false;
      resetField();
      documento.value = {};
      await getDocumentiCorso(id).then((res) => {
        if (res.status != 200) {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "modal_edit_corso"
          );
          return;
        }
        // res.data.results.forEach((element) => {
        //   documenti.value.push(element);
        // });
        documento.value = res.data.results[0];
        caricato.value = true;
      });
      await detailCorso(id).then((res) => {
        jsonDetailCorso.value = res.results[0];
        stagione.value = parseInt(jsonDetailCorso.value.stagione);
        denominazione.value = jsonDetailCorso.value.denominazione;
        id_Tipo_Corso.value = jsonDetailCorso.value.id_tipo_corso;
        store.dispatch("setStoreData", {
          keys: { id_Tipo_Corso: id_Tipo_Corso.value },
          apiLink: globalApi.LOOKUP_SPECIALIZZAZIONE_CORSI,
        });
        id_Sottotipo_Corso.value = jsonDetailCorso.value.id_sottotipo_corso;
        sottotipo_agg.value = JSON.parse(jsonDetailCorso.value.sottotipo_agg);
        if (sottotipo_agg.value) {
          sottotipo_agg.value.forEach((element) => {
            selectedSottotipo.value.push([element.id]);
            inputElement(selectedSottotipo.value);
          });
        }
        costo.value = jsonDetailCorso.value.costo;
        data_Inizio.value = jsonDetailCorso.value.data_inizio;
        data_Fine.value = jsonDetailCorso.value.data_fine;
        iscr_Inizio.value = jsonDetailCorso.value.iscr_inizio;
        iscr_Fine.value = jsonDetailCorso.value.iscr_fine;
        pubblicazione_Sul_Sito.value =
          jsonDetailCorso.value.pubblicazione_sul_sito;
        visibile_online.value = jsonDetailCorso.value.visibile_online ? 1 : 0;
        iscrizione_online.value = jsonDetailCorso.value.iscrizione_online
          ? 1
          : 0;
        id_livello_visibilita.value =
          jsonDetailCorso.value.id_livello_visibilita;
        crediti.value = jsonDetailCorso.value.crediti;
        curatore.value = jsonDetailCorso.value.curatore;
        estero.value = parseInt(jsonDetailCorso.value.estero);
        store.commit("spliceArrayFromName", "istat_province");
        if (estero.value === 0) {
          id_Regione.value = parseInt(jsonDetailCorso.value.id_regione);
          store.dispatch("setStoreData", {
            keys: { codR: id_Regione.value },
            apiLink: globalApi.LUOGHI_PROVINCE_ISTAT,
          });
          id_Provincia.value = parseInt(jsonDetailCorso.value.id_provincia);
          comune_txt.value = jsonDetailCorso.value.comune;
          inputFocus.value = "";
          id_Comune.value = parseInt(jsonDetailCorso.value.id_comune);
          selectedProvincia.value = true;
          province.value.splice(0, province.value.length);
          istat_regioni_province.value.forEach((element) => {
            if (element.codR === parseInt(id_Regione.value)) {
              element.Province.forEach((prov) => {
                province.value.push(prov);
              });
            }
          });
        } else {
          stato.value = jsonDetailCorso.value.stato;
          localita.value = jsonDetailCorso.value.localita;
        }
        luogo.value = jsonDetailCorso.value.luogo;
        telefono.value = jsonDetailCorso.value.telefono;
        fax.value = jsonDetailCorso.value.fax;
        cap.value = jsonDetailCorso.value.cap;
        org_Tipo.value = parseInt(jsonDetailCorso.value.org_tipo);
        switch (org_Tipo.value) {
          case 1:
            org_Denominazione_1.value = jsonDetailCorso.value.org_denominazione;
            break;
          case 2:
            selectedComitati.value = jsonDetailCorso.value.org_denominazione;
            break;
          case 3:
            nome_societa.value = jsonDetailCorso.value.org_denominazione;
            break;
          case 6:
            org_Denominazione_6.value = jsonDetailCorso.value.org_denominazione;
            break;
        }
        org_Denominazione.value = jsonDetailCorso.value.org_denominazione;
        caricato.value = true;
      });
    };

    const caricato = ref(false);

    watch(
      () => props.id_corso,
      (value) => {
        if (value) loadCorso(value);
      }
    );

    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(e.target.files, supportedFormatCorsi.value);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    const downloadingFile = ref(false);
    const downloadDocumento = async (item_id, file_name) => {
      if (!downloadingFile.value) {
        downloadingFile.value = true;
        await downloadElement(item_id, "corso", file_name);
        // .then((res) => {
        //   if (res.status != 200) {
        //     alertFailed(
        //       res.data.message
        //         ? res.data.message
        //         : "Attenzione! Si è verificato un errore. Riprovare più tardi"
        //     );
        //   }
        // });
        downloadingFile.value = false;
      }
    };

    const removeDoc = async (item_id, anno, id_Tipo, acl) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Una volta cancellato, non portai piu recuperare il file!",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteDocumento(item_id, props.id_corso).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("File eliminato");
              loadCorso(props.id_corso);
              // emit("refreshList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };

    const supportedFormatCorsi = ref(
      ".pdf, .zip, .doc, .ppt, .xls, .docx, .pptx, .xlsx, .jpg, .jpeg, .png"
    );

    // commento per me stesso
    // non usare il watch così
    // nel watch verificare solo il cambio id passato come props
    // chiamata function farla all'interno del componente
    /*watch(() => {
      if (props.isLoaded == true && renderEnd.value == false) {
        resetField();
        renderEnd.value = true;
      }
    });*/

    const inputElement = (selected) => {
      id_sottotipo_corso_agg.value = selected.join(",");
    };

    const setEstero = (event) => {
      estero.value = event;
      id_Regione.value = null;
      id_Provincia.value = null;
      inputFocus.value = null;
      id_Comune.value = 0;
      selectedProvincia.value = false;
      stato.value = null;
      localita.value = null;
      luogo.value = null;
      cap.value = null;
      comune_txt.value = "";
    };

    return {
      removeDoc,
      downloadDocumento,
      onInputChange,
      addFile,
      removeFile,
      file,
      estero,
      alertCampiObb,
      schema,
      onSubmit,
      onInvalidSubmit,
      resetField,
      format,
      defaultStagione,
      stagione,
      denominazione,
      setCorsoStagione,
      snm_corsi_tipo,
      snm_corsi_sottotipo,
      id_Tipo_Corso,
      setCorsoTipo,
      getSpecializzCorsi,
      setCorsoSottotipo,
      resetCorsiSpec,
      id_Sottotipo_Corso,
      costo,
      data_Inizio,
      data_Fine,
      iscr_Inizio,
      iscr_Fine,
      pubblicazione_Sul_Sito,
      visibile_online,
      iscrizione_online,
      id_livello_visibilita,
      validateNumber,
      crediti,
      curatore,
      istat_regioni_province: computed(() =>
        store.getters.getStateFromName("istat_regioni_province")
      ),
      province,
      id_Regione,
      id_Provincia,
      setRegioneCorso,
      setProvinciaCorso,
      comune_txt,
      inputFocus,
      spliceComuniIstat,
      id_Comune,
      selectedProvincia,
      setComuni,
      comuniIstat: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      statiResidenza,
      stato,
      setNazioneCorso,
      localita,
      luogo,
      telefono,
      fax,
      cap,
      snm_tipo_organizzazione,
      org_Tipo,
      setCorsoTipoOrganizzazione,
      org_Denominazione,
      comitati: computed(() => store.getters.getStateFromName("comitati")),
      nome_societa,
      getSocieta,
      resetSocieta,
      spliceSocieta,
      societa: computed(() => store.getters.societaNomeCodAff),
      selectedComitati,
      setCorsoOrganizzatoreComitato,
      setCorsoCostoBase,
      org_Denominazione_1,
      org_Denominazione_3,
      org_Denominazione_6,
      setOrganizzatoreAltro,
      caricato,
      loadCorso,
      disableButton,
      isLoading,
      documento,
      supportedFormatCorsi,
      selectedSottotipo,
      inputElement,
      id_sottotipo_corso_agg,
      setEstero,
      lookupSTDSiNo: [
        { id: 0, label: "No" },
        { id: 1, label: "Si" },
      ],
      snm_corsi_livelli_visibilita,
    };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
