<template>
  <div>
    <div class="card shadow">
      <div
        class="pt-4 pb-4 pe-4 ps-4"
        @keyup.enter="$emit('getCorsiAreaInsegnanteList')"
      >
        <div class="row">
          <div class="col-md-2 filter" v-if="fromInsegnante">
            <SelectInput
              :options="stagioni"
              name="stagioni"
              placeholder="Stagione"
              :value="stagione"
              @changeSelect="setStagione"
            />
          </div>
          <div class="col-md-3 filter">
            <SelectInput
              :options="snm_corsi_tipo"
              name="snm_corsi_tipo"
              placeholder="Tipologia corsi"
              :value="tipologiaCorsiAreaInsegnante"
              @changeSelect="
                setTipologiaCorsiAreaInsegnante($event);
                getSpecializzCorsiAreaInsegnante(tipologiaCorsiAreaInsegnante);
                setSpecializzCorsiAreaInsegnante(null);
                resetCorsiAreaInsegnanteSpec();
              "
            />
          </div>
          <div class="col-md-3 filter">
            <SelectInput
              :options="snm_corsi_sottotipo"
              name="snm_corsi_sottotipo"
              placeholder="Specializzazione corsi"
              :value="specializzCorsiAreaInsegnante"
              @changeSelect="setSpecializzCorsiAreaInsegnante($event)"
              :disabled="!tipologiaCorsiAreaInsegnante"
            />
          </div>
          <div class="col-md-4 filter">
            <input
              type="text"
              class="form-control"
              placeholder="Denominazione"
              aria-label=""
              :value="denominazioneCorsiAreaInsegnante"
              @input="setDenominazioneCorsiAreaInsegnante"
            />
          </div>
        </div>
        <div class="text-center mt-5">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600"
            @click="$emit('getCorsiAreaInsegnanteList')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="
              resetStagione();
              resetCorsiAreaInsegnanteSpec();
              specializzCorsiAreaInsegnante = null;
            "
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, getCurrentInstance, watch } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-corsi-iscrizioni",
  emits: ["resetFilters", "getCorsiAreaInsegnanteList"],
  props: {
    fromInsegnante: {
      type: Boolean,
    },
  },
  components: {
    SelectInput,
  },
  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const snm_corsi_tipo = computed(() =>
      store.getters.getStateFromName("snm_corsi_tipo")
    );

    const snm_corsi_sottotipo = computed(() =>
      store.getters.getStateFromName("snm_corsi_sottotipo")
    );

    const tipologiaCorsiAreaInsegnante = computed(
      () => store.getters.tipologiaCorsiAreaInsegnante
    );
    const specializzCorsiAreaInsegnante = computed(
      () => store.getters.specializzCorsiAreaInsegnante
    );
    const denominazioneCorsiAreaInsegnante = computed(
      () => store.getters.denominazioneCorsiAreaInsegnante
    );

    const stagione = computed(() => store.getters.stagioneSelected);
    const defaultStagione = computed(() => store.getters.defaultStagione);
    const stagioneCorrente = computed(() => store.getters.stagioneCorrente);

    const checkStagioneCorrente = () => {
      if (defaultStagione.value == new Date().getFullYear()) {
        store.commit("setStagioneCorrente", true);
      } else store.commit("setStagioneCorrente", false);
    };

    const setStagione = (event) => {
      store.commit("setStagioneSelected", parseInt(event));
      store.commit("setStagioneDefault", parseInt(event));
      store.commit("setStagione", parseInt(event));
      checkStagioneCorrente();
    };

    const reset = ref(null);
    const resetStagione = () => {
      reset.value = true;
      store.commit("setStagioneSelected", new Date().getFullYear());
      store.commit("setStagioneDefault", new Date().getFullYear());
      store.commit("setStagione", new Date().getFullYear());
      store.commit("spliceArrayFromName", "snm_corsi_sottotipo");
      store.commit("resetFiltersCorsiAreaInsegnante");
      emit("getCorsiAreaInsegnanteList");
      checkStagioneCorrente();
      setTimeout(() => {
        reset.value = false;
      }, 1000);
    };

    watch(stagione, () => {
      if (!reset.value) emit("getCorsiAreaInsegnanteList");
    });

    const keys = ref("sct");
    if (!snm_corsi_tipo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const setDenominazioneCorsiAreaInsegnante = (e) => {
      const string = e.target.value;
      store.commit("setDenominazioneCorsiAreaInsegnante", string);
    };

    const setTipologiaCorsiAreaInsegnante = (event) => {
      store.commit("setTipologiaCorsiAreaInsegnante", event);
    };

    const setSpecializzCorsiAreaInsegnante = (event) => {
      store.commit("setSpecializzCorsiAreaInsegnante", event);
    };

    const getSpecializzCorsiAreaInsegnante = (id) => {
      store.dispatch("setStoreData", {
        keys: { id_Tipo_Corso: id },
        apiLink: globalApi.LOOKUP_SPECIALIZZAZIONE_CORSI,
      });
    };

    const resetCorsiAreaInsegnanteSpec = () => {
      store.commit("spliceArrayFromName", "snm_corsi_sottotipo");
      specializzCorsiAreaInsegnante.value = null;
    };

    return {
      resetCorsiAreaInsegnanteSpec,
      setStagione,
      stagioni: computed(() => store.getters.stagioni),
      stagione,
      defaultStagione,
      resetStagione,
      snm_corsi_tipo,
      getSpecializzCorsiAreaInsegnante,
      snm_corsi_sottotipo,
      setDenominazioneCorsiAreaInsegnante,
      denominazioneCorsiAreaInsegnante,
      setSpecializzCorsiAreaInsegnante,
      specializzCorsiAreaInsegnante,
      setTipologiaCorsiAreaInsegnante,
      tipologiaCorsiAreaInsegnante,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedcorsi_area_insegnante_list")
      ),
      stagioneCorrente,
      checkStagioneCorrente,
    };
  },
});
</script>

<style></style>
