<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <div class="col-6">
        <div
          class="btn btn-light text-gray-700 fs-6 text-center"
          v-if="!fromInsegnante"
        >
          <input
            class="form-check-input me-2"
            type="checkbox"
            @click="setTutteLeStagioniCorsiAreaInsegnante"
            :checked="tutteStagioniCorsiAreaInsegnante"
          />visualizza tutte le stagioni
        </div>
      </div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          corsi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        corsi_area_insegnante_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <a
          v-else-if="!hasBacheca"
          :href="redirectInsegnanti"
          class="btn text-hover-primary text-gray-600 fs-5 text-end p-0 m-0"
        >
          <i class="bi bi-house fs-2 text-gray-800"> </i>
        </a>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        ></span
      >
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="corsi_area_insegnante_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsCorsiAreaInsegnante"
        @current-change="setCurrentPageCorsiAreaInsegnante"
      >
        <template v-slot:cell-stagione="{ row: data }">{{
          data.stagione
        }}</template>
        <template v-slot:cell-tipo_corso="{ row: data }"
          ><div class="tab-long">
            {{ data.corso }} -
            <span>
              {{ data.specializzazione_corso }}
            </span>
            <div
              v-for="(sottotipo, index) in JSON.parse(data.sottotipo_agg)"
              :key="index"
            >
              {{ sottotipo.label }}
            </div>
          </div></template
        >
        <template v-slot:cell-denominazione="{ row: data }"
          ><div class="tab-long">
            {{ data.denominazione }}
            <button
              class="btn btn-sm dropdown p-1"
              data-bs-toggle="modal"
              data-bs-target="#modal_edit_corso"
              @click.prevent="
                viewEditCorso(data.id);
                onlyView = true;
              "
            >
              <i class="bi bi-info-circle text-dark fs-5 pb-1"></i>
            </button></div
        ></template>
        <template v-slot:cell-periodo="{ row: data }">
          {{ getFormatDate(data.data_inizio) }} -
          {{ getFormatDate(data.data_fine) }}
        </template>
        <template v-slot:cell-luogo="{ row: data }"
          ><div class="tab-long">{{ data.luogo }}</div></template
        >
        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group">
            <button
              v-if="data.id_iscrizione"
              type="button"
              class="badge bg-light-primary text-primary rounded fs-6 me-4"
              data-bs-toggle="modal"
              :data-bs-target="`#kt_modal_documenti_partecipante${data.id}`"
              @click="
                stagioneCorso = data.stagione;
                id_tipo_corso = data.tipo_corso;
                id_corso = data.id;
                data.viewDocs = true;
              "
            >
              <i class="bi bi-files text-primary fw-bold me-2"></i>Documenti
            </button>
            <ViewDocumentiCorsoIns
              :id_Corso="data.id"
              :selectedIdPersona="id_persona"
              :stagioneCorso="data.stagione"
              :id_tipo_corso="data.tipo_corso"
              :puoCaricareDocumenti="data.puo_caricare_documenti"
              :viewDocs="data.viewDocs"
              @refreshList="refreshList"
            />
            <Popper
              arrow
              content="Non è più possibile annullare l'iscrizione al corso."
            >
              <button
                v-if="data.id_iscrizione && !data.puo_disiscriversi"
                type="button"
                class="badge bg-light text-gray-600 rounded fs-6"
              >
                Annulla iscrizione
              </button></Popper
            >
            <Popper arrow content="Le iscrizioni al corso sono chiuse.">
              <button
                v-if="
                  !data.id_iscrizione && new Date(data.iscr_fine) < new Date()
                "
                type="button"
                class="badge bg-light text-gray-600 rounded fs-6"
              >
                Iscriviti
              </button></Popper
            >
            <button
              v-if="
                !data.id_iscrizione && new Date(data.iscr_fine) >= new Date()
              "
              type="button"
              class="badge bg-light-success text-success rounded fs-6"
              @click="iscriviti(data.id)"
            >
              Iscriviti
            </button>

            <button
              v-if="data.id_iscrizione && data.puo_disiscriversi"
              type="button"
              class="badge bg-light-danger text-danger rounded fs-6"
              @click="disiscriviti(data.id, data.id_iscrizione)"
            >
              Annulla iscrizione
            </button>
          </div>
        </template>
      </Datatable>
    </div>
    <EditCorso :id_corso="id_corso" :onlyView="true" />
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { detailCorso } from "@/requests/snmCorsi";

import EditCorso from "../gestione-corsi/EditCorso.vue";
import ViewDocumentiCorsoIns from "./ViewDocumentiCorsoIns.vue";

import {
  addPartecipanteCorso,
  deletePartecipanteCorso,
} from "@/requests/snmCorsi";
import { useRoute } from "vue-router";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import isEnabled from "@/composables/isEnabled.js";

import Popper from "vue3-popper";

import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";

export default {
  name: "table-corsi-iscrizioni",
  components: {
    Datatable,
    Loading,
    EditCorso,
    ViewDocumentiCorsoIns,
    Popper,
  },
  emits: ["getCorsiAreaInsegnanteList"],
  props: {
    fromInsegnante: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const id_persona = ref(route.params.id_persona);
    const stagioneCorso = ref(null);
    const id_tipo_corso = ref(null);

    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    const setFetchRowsCorsiAreaInsegnante = (e) => {
      store.commit("setFetchRowsCorsiAreaInsegnante", e);
      emit("getCorsiAreaInsegnanteList");
    };
    const setCurrentPageCorsiAreaInsegnante = (page) => {
      store.commit("setCurrentPageCorsiAreaInsegnante", page);
      emit("getCorsiAreaInsegnanteList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnCorsiAreaInsegnante", columnName);
      store.commit("setSortOrderCorsiAreaInsegnante", order);
      emit("getCorsiAreaInsegnanteList");
    };

    const refreshList = () => {
      emit("getCorsiAreaInsegnanteList");
    };

    const tableHeader = ref([
      {
        name: "Stagione",
        key: "stagione",
        sortable: false,
      },
      {
        name: "Tipologia",
        key: "tipo_corso",
      },
      {
        name: "Denominazione",
        key: "denominazione",
      },
      {
        name: "Periodo",
        key: "periodo",
        sortable: false,
      },
      {
        name: "Luogo",
        key: "luogo",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const isLoaded = ref(false);
    const jsonViewEditCorso = ref({});
    const id_corso = ref(null);

    const viewDetailCorso = (id) => {
      isLoaded.value = false;
      detailCorso(id).then((res) => {
        isLoaded.value = true;
        jsonViewEditCorso.value = res.results[0];
      });
    };

    const viewEditCorso = (id) => {
      isLoaded.value = false;
      id_corso.value = id;
    };

    const isLoading = ref(false);

    const iscriviti = (id_corso) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "procedendo verrà inviata la richiesta di iscrizione.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Invia",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await addPartecipanteCorso({
            id_Persona: id_persona.value,
            id_Corso: id_corso,
            sconto: false,
          }).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("Richiesta di iscrizione avvenuta con successo");
              emit("getCorsiAreaInsegnanteList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };

    const disiscriviti = (id_corso, id_iscrizione) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "procedendo verrà annullata l'iscrizione al corso.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Chiudi",
        confirmButtonText: "Annulla Iscrizione",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deletePartecipanteCorso(
            id_iscrizione,
            id_corso,
            id_persona.value
          ).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("Iscrizione annullata");
              emit("getCorsiAreaInsegnanteList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    const setTutteLeStagioniCorsiAreaInsegnante = () => {
      store.commit("setTutteLeStagioniCorsiAreaInsegnante");
      emit("getCorsiAreaInsegnanteList");
    };

    return {
      getFormatDate,
      corsi_area_insegnante_list: computed(() =>
        store.getters.getStateFromName("resultscorsi_area_insegnante_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedcorsi_area_insegnante_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordcorsi_area_insegnante_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuscorsi_area_insegnante_list")
      ),
      currentPage: computed(() => store.getters.currentPageCorsiAreaInsegnante),
      rowsToSkip: computed(() => store.getters.rowsToSkipCorsiAreaInsegnante),
      fetchRows: computed(() => store.getters.fetchRowsCorsiAreaInsegnante),
      sortColumn: computed(() => store.getters.sortColumnCorsiAreaInsegnante),
      sortOrder: computed(() => store.getters.sortOrderCorsiAreaInsegnante),
      setFetchRowsCorsiAreaInsegnante,
      setCurrentPageCorsiAreaInsegnante,
      setSortOrderColumn,
      tableHeader,
      isLoaded,
      viewDetailCorso,
      jsonViewEditCorso,
      viewEditCorso,
      refreshList,
      id_corso,
      id_persona,
      stagioneCorso,
      id_tipo_corso,
      isLoading,
      iscriviti,
      disiscriviti,

      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,
      isEnabled,

      tutteStagioniCorsiAreaInsegnante: computed(
        () => store.getters.tutteStagioniCorsiAreaInsegnante
      ),
      setTutteLeStagioniCorsiAreaInsegnante,
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
  text-align: center;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
